@media (max-width: $screen-sm) {
    // sm-view
    .vacancy-detail { 
        margin: 0 0 35px;
        .vacancy-detail-holder { 
            width: 110.35%;
            margin-left: -5.175%;
            .vacancy-info { 
                width: 100%;
                float: left;
                margin: 0 0 1px;
                .vacancy-info-holder { 
                    padding: 21px 4.69% 3px;
                    .heading {
                        margin: 0 0 15px;
                        h1, .h1 {
                            font-size: 21px;
                            line-height: 29.4px;
                        }
                    }

                    .salary { 
                        font-size: 18px;
                        line-height: 25.2px;
                    }

                    .job-description { 
                        ul, ol {
                            margin-bottom: 18px;
                        }
                    }
                }
            }

            .vacancy-action { 
                width: 100%;
                float: left;
                margin: 0;
                .vacancy-action-holder { 
                    padding: 30px 4.69% 28px;
                    .heading {}

                    form { 

                        .form-group { 
            
                            .form-col { 
            
                                .form-field { 
                                    label.input {

                                    }

                                    label.file-upload {
                                        width: 290px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
