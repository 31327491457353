.vacancies-list {
  display: inline-block;
  width: 100%;
  margin: 0 0 80px;
}
.vacancies-list .vacncies-list-holder {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.vacancies-list .vacncies-list-holder .vacancy-item {
  float: left;
  width: 33.333%;
  height: auto;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 0 20px;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  background-image: url(../img/vacancies-list-bkg.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto;
  background-clip: border-box;
  border-radius: 4px;
  padding: 30px 40px 30px;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  min-height: 140px;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .name {
  display: inline-block;
  width: 100%;
  font-size: 21px;
  font-weight: 700;
  line-height: 29.4px;
  line-height: 29.4px;
  margin: 0 0 12px;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .name a {
  color: #101517;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .name a:hover {
  color: #12A356;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .salary {
  display: inline-block;
  width: 100%;
  font-size: 21px;
  font-weight: 700;
  line-height: 29.4px;
  color: #12A356;
  margin: 0 0 3px;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .requirements {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .action {
  display: inline-block;
  width: 100%;
}
.vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .action .btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  padding: 13px 45px;
}

@media (max-width: 1279px) {
  .vacancies-list {
    margin: 0 0 63px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item {
    width: 50%;
    margin: 0 0 23px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info {
    min-height: 137px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .name {
    margin: 0 0 8px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .salary {
    margin: 0 0 5px;
  }
}
@media (max-width: 767px) {
  .vacancies-list {
    margin: 0 0 30px;
  }
  .vacancies-list .vacncies-list-holder {
    width: 100%;
    margin: 0;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item {
    width: 100%;
    padding: 0;
    margin: 0 0 17px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder {
    padding: 33px 27px 30px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info {
    min-height: 140px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .name {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    margin: 0 0 8px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .salary {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    margin: 0 0 7px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .vacancy-info .requirements {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
  }
  .vacancies-list .vacncies-list-holder .vacancy-item .vacancy-item-holder .action .btn {
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
  }
}
.vacancy-detail {
  display: inline-block;
  width: 100%;
  margin: 0 0 98px;
}
.vacancy-detail .vacancy-detail-holder {
  display: inline-block;
  width: 100%;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info {
  width: calc(100% - 305px - 1px);
  float: left;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 40px 25px;
  background: white;
  border-radius: 6px 0px 6px 6px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 17px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading h1, .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading .h1 {
  margin: 0;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .salary {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  line-height: 33.6px;
  color: #12A356;
  margin: 0 0 17px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description {
  display: inline-block;
  width: 100%;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description h3, .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description .h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  margin: 0 0 16px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: #4D5153;
  margin: 0 0 15px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description ul, .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description ol {
  margin-bottom: 19px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description ul.dots li {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: #4D5153;
  margin: 0 0 9px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description ul.dots li:before {
  top: 8px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action {
  width: 305px;
  margin-left: 1px;
  float: left;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder {
  display: inline-block;
  width: 100%;
  background: white;
  box-sizing: border-box;
  padding: 30px 30px;
  border-radius: 0px 0px 6px 0px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder .heading h2, .vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder .heading .h2 {
  text-align: center;
  color: #12A356;
  font-size: 21px;
  font-weight: 800;
  line-height: 29.4px;
  text-align: center;
  margin: 0;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field {
  margin: 0 0 17px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field label.input textarea {
  height: 100px;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field label.file-upload {
  margin: -5px 0 0;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field label.file-upload .btn {
  width: 100%;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field label.file-upload .filename {
  width: 100%;
  margin: 5px 0 1px;
  text-align: center;
  display: inline-block;
  float: none;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .action {
  margin: -4px 0 0;
}
.vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .action .submit .btn {
  padding: 13px 10px;
}
@media (max-width: 1279px) {
  .vacancy-detail {
    margin: 0 0 58px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info {
    width: 100%;
    float: left;
    margin: 0 0 1px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder {
    padding: 30px 5.8% 13px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading h1, .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading .h1 {
    font-size: 21px;
    line-height: 29.4px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-action {
    width: 100%;
    float: left;
    margin: 0;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder {
    padding: 30px 5.8% 30px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field label.file-upload {
    margin: -5px auto 0;
    width: 244px;
  }
}
@media (max-width: 767px) {
  .vacancy-detail {
    margin: 0 0 35px;
  }
  .vacancy-detail .vacancy-detail-holder {
    width: 110.35%;
    margin-left: -5.175%;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info {
    width: 100%;
    float: left;
    margin: 0 0 1px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder {
    padding: 21px 4.69% 3px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading {
    margin: 0 0 15px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading h1, .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .heading .h1 {
    font-size: 21px;
    line-height: 29.4px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .salary {
    font-size: 18px;
    line-height: 25.2px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description ul, .vacancy-detail .vacancy-detail-holder .vacancy-info .vacancy-info-holder .job-description ol {
    margin-bottom: 18px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-action {
    width: 100%;
    float: left;
    margin: 0;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder {
    padding: 30px 4.69% 28px;
  }
  .vacancy-detail .vacancy-detail-holder .vacancy-action .vacancy-action-holder form .form-group .form-col .form-field label.file-upload {
    width: 290px;
  }
}