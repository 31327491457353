.vacancies-list { 
    display: inline-block;
    width: 100%;
    margin: 0 0 80px;
	.vacncies-list-holder { 
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -10px;
		.vacancy-item { 
            float: left;
            width: 33.333%;
            height: auto;
            box-sizing: border-box;
            padding: 0 10px;
            margin: 0 0 20px;
			.vacancy-item-holder { 
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background-color: white;
                background-image: url(../img/vacancies-list-bkg.png);
                background-repeat: no-repeat;
                background-position: 100% 100%;
                background-size: auto;
                background-clip: border-box;
                border-radius: 4px;
                padding: 30px 40px 30px;
				.vacancy-info { 
                    display: inline-block;
                    width: 100%;
                    flex-grow: 1;
                    min-height: 140px;
					.name { 
                        display: inline-block;
                        width: 100%;
                        font-size: 21px;
                        font-weight: 700;
                        line-height: 29.4px;
                        line-height: 29.4px;
                        margin: 0 0 12px;
						a { 
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
					}

					.salary {
                        display: inline-block;
                        width: 100%;
                        font-size: 21px;
                        font-weight: 700;
                        line-height: 29.4px;
                        color: $brand-primary;
                        margin: 0 0 3px;
                    }

					.requirements { 
                        display: inline-block;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22.4px;
                    }
				}

				.action { 
                    display: inline-block;
                    width: 100%;
					.btn { 
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 22.4px;
                        padding: 13px 45px;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/vacancies/list";
@import "../../media/mobile/includes/vacancies/list";
