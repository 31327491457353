.vacancy-detail { 
    display: inline-block;
    width: 100%;
    margin: 0 0 98px;
	.vacancy-detail-holder { 
        display: inline-block;
        width: 100%;
		.vacancy-info { 
            width: calc(100% - 305px - 1px);
            float: left;
			.vacancy-info-holder { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 40px 40px 25px;
                background: white;
                border-radius: 6px 0px 6px 6px;
				.heading { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 17px;
					h1, .h1 { 
                        margin: 0;
					}
				}

				.salary { 
                    display: inline-block;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 800;
                    line-height: 33.6px;
                    color: $brand-primary;
                    margin: 0 0 17px;
				}

				.job-description { 
                    display: inline-block;
                    width: 100%;
					h3, .h3 { 
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 25.6px;
                        margin: 0 0 16px;                      
					}

					p { 
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22.4px;
                        color: $gray-dark;    
                        margin: 0 0 15px;                
					}

                    ul, ol {
                        margin-bottom: 19px;
                    }

					ul.dots { 

						li { 
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22.4px;
                            color: $gray-dark; 
                            margin: 0 0 9px;
                            &:before {
                                top: 8px;
                            }
						}
					}
				}
			}
		}

		.vacancy-action { 
            width: 305px;
            margin-left: 1px;
            float: left;
			.vacancy-action-holder { 
                display: inline-block;
                width: 100%;
                background: white;
                box-sizing: border-box;
                padding: 30px 30px;
                border-radius: 0px 0px 6px 0px;
                .heading {
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 16px;
                    h2, .h2 { 
                        text-align: center;
                        color: $brand-primary;
                        font-size: 21px;
                        font-weight: 800;
                        line-height: 29.4px;
                        text-align: center;
                        margin: 0;
                    }
                }

                form { 

                    .form-group { 
        
                        .form-col { 
        
                            .form-field { 
                                margin: 0 0 17px;
                                label.input { 
        
                                    .name { 
        
                                    }
        
                                    input { 
        
                                    }
        
                                    textarea {
                                        height: 100px;
                                    }
                                }

                                label.file-upload {
                                    margin: -5px 0 0;
                                    .btn {
                                        width: 100%;
                                    }

                                    .filename {
                                        width: 100%;
                                        margin: 5px 0 1px;
                                        text-align: center;
                                        display: inline-block;
                                        float: none;
                                    }
                                }
                            }
                        }
                    }
        
                    .action { 
                        margin: -4px 0 0;
                        .submit { 
        
                            .btn { 
                                padding: 13px 10px;
                            }
                        }
        
                        .agree-col { 
        
                            a { 
        
                            }
                        }
                    }
                }

			}
		}
	}
}

@import "../../media/tablet/includes/vacancies/detail";
@import "../../media/mobile/includes/vacancies/detail";
