@media (max-width: $screen-sm) {
    // sm-view
    .vacancies-list { 
        margin: 0 0 30px;
        .vacncies-list-holder { 
            width: 100%;
            margin: 0;
            .vacancy-item { 
                width: 100%;
                padding: 0;
                margin: 0 0 17px;
                .vacancy-item-holder { 
                    padding: 33px 27px 30px;
                    .vacancy-info { 
                        min-height: 140px;
                        .name {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 25.2px;
                            margin: 0 0 8px;
                        }

                        .salary {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 25.2px;
                            margin: 0 0 7px;
                        }

                        .requirements { 
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22.4px;
                        }
                    }

                    .action {
                        .btn {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 22.4px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
