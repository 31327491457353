@media (max-width: $screen-lg) {
    // lg-md view
    .vacancy-detail { 
        margin: 0 0 58px;
        .vacancy-detail-holder { 
            .vacancy-info { 
                width: 100%;
                float: left;
                margin: 0 0 1px;
                .vacancy-info-holder { 
                    padding: 30px 5.8% 13px;
                    .heading {
                        h1, .h1 {
                            font-size: 21px;
                            line-height: 29.4px;
                        }
                    }
                }
            }

            .vacancy-action { 
                width: 100%;
                float: left;
                margin: 0;
                .vacancy-action-holder { 
                    padding: 30px 5.8% 30px;
                    .heading {}

                    form { 

                        .form-group { 
            
                            .form-col { 
            
                                .form-field { 
                                    label.input {

                                    }

                                    label.file-upload {
                                        margin: -5px auto 0;
                                        width: 244px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
