@media (max-width: $screen-lg) {
    // lg-md view
    .vacancies-list { 
        margin: 0 0 63px;
        .vacncies-list-holder { 
            .vacancy-item { 
                width: 50%;
                margin: 0 0 23px;
                .vacancy-item-holder { 
                    .vacancy-info { 
                        min-height: 137px;
                        .name {
                            margin: 0 0 8px;
                        }

                        .salary {
                            margin: 0 0 5px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
